import React, { useState, useEffect } from 'react';
import { OpenAI } from "openai";
import { useCallback, useRef } from 'react';
import './App.css';

function App() {
  const [apiKey, setApiKey] = useState(localStorage.getItem('dalleApiKey') || '');
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const intervalRef = useRef(null);

  const handleApiKeySubmit = async (event) => {
    event.preventDefault();
    localStorage.setItem('dalleApiKey', apiKey);
    await fetchImages();
  };

  // const startImageFetch = async () => {
  //   await fetchImages();
  // };

  const fetchImages = useCallback(async () => {
    const openai = new OpenAI({
      apiKey: apiKey, dangerouslyAllowBrowser: true
    });

    try {
      const response = await openai.images.generate({
        model: "dall-e-3",
        prompt: "UHD National Geographic photo of the beach in Belize",
        n: 1,
        size: "1792x1024",
      });
      // Assuming response contains URLs of generated images
      const imageUrls = response.data.map(item => item.url);
      setImages(imageUrls);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  }, [apiKey]);

  // useEffect(() => {
  //   if (images.length > 0) {
  //     intervalRef.current = setInterval(() => {
  //       setCurrentImage(currentImage => (currentImage + 1) % images.length);
  //     }, 30000);
  //   }
  // }, [images]);

  // useEffect(() => {
  //   return () => {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (apiKey) {
      // Clear existing interval if there is one
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      // Immediately fetch images and set up an interval for future fetches
      fetchImages();
      intervalRef.current = setInterval(() => {
        fetchImages();
      }, 300000); // Fetch new images every 30 seconds
    }

    // Clear interval on component unmount or apiKey change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [apiKey, fetchImages]);

  return (
    <div className="App">
      {!apiKey && (
        <form onSubmit={handleApiKeySubmit}>
          <input 
            type="text" 
            value={apiKey}
            onChange={e => setApiKey(e.target.value)}
            placeholder="Enter DALL·E API Key"
          />
          <button type="submit">Submit</button>
        </form>
      )}
      {images.length > 0 && (
        <img src={images[currentImage]} alt="display" style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} />
      )}
    </div>
  );
}

export default App;
